import Vue from "vue";
import AppointmentsPaymentDialogContent from "./AppointmentsPaymentDialogContent.vue";
export default Vue.extend({
    props: {
        appointments: {
            type: Array,
            required: true
        },
        onClose: {
            type: Function,
            required: false
        }
    },
    data() {
        return {
            animationSettings: { effect: "None" },
            buttons: [
                {
                    click: function () {
                        document.getElementById("appointments-payment-dialog").ej2_instances[0].hide();
                    },
                    buttonModel: { content: this.$t('shared.close'), isPrimary: true }
                }
            ],
            contentTemplate: function () {
                return {
                    template: AppointmentsPaymentDialogContent
                };
            }
        };
    },
    methods: {
        show() {
            this.$refs.appointmentsPaymentDialog.show();
        },
        hide() {
            this.$refs.appointmentsPaymentDialog.hide();
        }
    }
});
