import Vue from "vue";
import { dateValidationRule } from "@/helpers/formValidation";
import { getToday } from "@/helpers/dateTimeHelper";
import SpinnerMixin from "@/mixins/spinner";
import gridMixin from "@/mixins/syncfusion/grid";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [gridMixin, SpinnerMixin],
    data() {
        return {
            paymentDate: null,
            paymentNotes: "",
            validationOptions: {
                rules: {
                    PaymentDate: {
                        // required: true,
                        date: [dateValidationRule],
                        dateNotFuture: [
                            (args) => {
                                const paymentDate = args.element.form.querySelector("#PaymentDate").ej2_instances[0].value;
                                const today = new Date();
                                return paymentDate <= today;
                            },
                            this.$t('payments.futureDateError')
                        ]
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        }),
        appointments() {
            const appointments = this.$parent.$parent.$props.appointments;
            if (appointments.length > 0) {
                if (appointments.every((appointment) => appointment.IsPaid === false)) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.paymentDate = getToday();
                }
                else {
                    const paymentDate = appointments[0].PaymentDate;
                    if (appointments.every((appointment) => appointment.PaymentDate.getTime() === paymentDate.getTime()))
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.paymentDate = paymentDate;
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    else
                        this.paymentDate = null;
                }
                const notes = appointments[0].PaymentNotes;
                if (appointments.every((appointment) => appointment.PaymentNotes === notes))
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.paymentNotes = notes;
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                else
                    this.paymentNotes = "";
            }
            return appointments;
        }
    },
    methods: {
        async update() {
            const appointments = this.$parent.$parent.$props.appointments.reduce((dict, value) => {
                dict[value.CustomerId] = [...dict[value.CustomerId] || [], value.AppointmentId];
                return dict;
            }, {});
            const customersAppointments = [];
            for (const [key, value] of Object.entries(appointments)) {
                customersAppointments.push({
                    customerId: +key,
                    appointmentIds: value
                });
            }
            const request = {
                customersAppointments: customersAppointments,
                paymentDate: this.paymentDate,
                paymentNotes: this.paymentNotes
            };
            await this.$store.dispatch("customer/updateAppointmentPaymentStatus", {
                appointmentsPayment: request
            });
            this.$parent.$parent.hide();
        },
        updateSuccess() {
            this.paymentDate = null;
            this.paymentNotes = "";
            this.$parent.$parent.hide();
            this.$toast.showSuccessToast(this.$tc("payments.update.toastSuccessTitle", this.$parent.$parent.$props.appointments.length), this.$tc("payments.update.toastSuccessContent", this.$parent.$parent.$props.appointments.length));
        },
        cancel() {
            this.paymentDate = null;
            this.paymentNotes = "";
            this.$parent.$parent.hide();
        }
    }
});
