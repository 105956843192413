import { Internationalization } from "@syncfusion/ej2-base";
import moment from "moment";
export function tryParseDate(locale, value) {
    const culture = new Internationalization(locale);
    try {
        return culture.parseDate(value, {
            type: "dateTime",
            skeleton: "yMd"
        });
    }
    catch {
        return null;
    }
}
;
export function tryParseTime(locale, value) {
    const culture = new Internationalization(locale);
    try {
        return culture.parseDate(value, {
            type: "time",
            skeleton: "Hm"
        });
    }
    catch {
        return null;
    }
}
export function formatDate(locale, value) {
    const culture = new Internationalization(locale);
    //return culture.formatDate(value, { type: "dateTime", skeleton: "yMdHm" });
    return culture.formatDate(value, { type: "date", skeleton: "yMd" });
}
export function formatTime(locale, value) {
    const culture = new Internationalization(locale);
    return culture.formatDate(value, { type: "time", skeleton: "Hm" });
}
export function formatDateTime(locale, value) {
    const culture = new Internationalization(locale);
    return culture.formatDate(value, { type: "dateTime", skeleton: "medium" });
}
export function getTime(date) {
    return (date.getHours() * 60 * 60 * 1000 +
        date.getMinutes() * 60 * 1000 +
        date.getSeconds() * 1000 +
        date.getMilliseconds());
}
export function getTotalHours(date) {
    return date.getHours() + (date.getMinutes() / 60);
}
export function getToday(addUtcOffset = true) {
    let date = new Date(new Date().setHours(0, 0, 0, 0));
    if (addUtcOffset)
        date = moment(date).add(moment().utcOffset(), "minutes").toDate();
    return date;
}
export function getFirstDayOfWeek(locale) {
    const culture = new Internationalization(locale);
    return culture.getFirstDayOfWeek();
}
export function add(date, amount, unit) {
    return moment(date).add(amount, unit).toDate();
}
export function addDays(date, days) {
    return add(date, days, "d");
}
export function addHours(date, hours) {
    return add(date, hours, "h");
}
export function addMinutes(date, minutes) {
    return add(date, minutes, "m");
}
export function addSeconds(date, seconds) {
    return add(date, seconds, "s");
}
export function getDifferenceInMinutes(startDate, endDate) {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    return moment.duration(startDateMoment.diff(endDateMoment)).asMinutes();
}
export function getNameOfWeek(dayOfWeek, locale) {
    const date = addDays(new Date(Date.UTC(2017, 0, 1)), dayOfWeek); // sunday
    const weekdayName = date.toLocaleDateString(locale, { weekday: 'long' });
    return weekdayName.charAt(0).toUpperCase() + weekdayName.slice(1);
}
